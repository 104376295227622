import React, { FC } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { DictMerchants, MerchantParams } from '../../../models/RulesModel';
import { Button, Col, Form, Row, Select } from 'antd';
import { useGetDictionaryQuery } from '../../../redux/api/dictionaryApi';
import { MaskedInput } from 'antd-mask-input';

const { Item } = Form;
const rules = [{ required: true, message: 'Обов`язкове поле' }];
const mask = [
    {
        mask: Number,
        scale: 2,
        signed: false,
        normalizeZeros: false,
        radix: ',',
        mapToRadix: [',', '.'],
    },
];

interface FormValues {
    id: number;
    value: string;
}
interface AddMerchantProps {
    onSubmit: (values: MerchantParams) => void;
}

const AddMerchant: FC<AddMerchantProps> = ({ onSubmit }) => {
    const [form] = useForm<FormValues>();
    const { data } = useGetDictionaryQuery({
        dictionaryName: 'dict_merchants',
        queryData: { count: true },
    });
    const merchants = data?.items?.map((item) => ({ label: item.merchantId, value: item.id }));
    const submit = async () => {
        try {
            const values = await form.validateFields();
            const merchant = data?.items?.find((merch) => merch.id === values.id);
            onSubmit({ value: values.value, merchant: merchant as DictMerchants });
            form.setFieldsValue({ value: undefined, id: undefined });
        } catch (e) {}
    };

    return (
        <Form
            form={form}
            validateMessages={{
                required: 'Обов’язкове поле!',
            }}>
            <Row className="rules-block__options_row" gutter={[8, 8]}>
                <Col span={1} />
                <Col span={4}>Додати мерчант</Col>
                <Col span={4}>
                    <Item name="id" rules={rules}>
                        <Select placeholder="Мерчант" options={merchants} />
                    </Item>
                </Col>
                <Col span={4}>
                    <Item name="value" rules={rules}>
                        <MaskedInput mask={mask} className="wWide" />
                    </Item>
                </Col>
                <Col>
                    <Button type="primary" onClick={submit}>
                        Add
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default AddMerchant;

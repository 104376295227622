import {
    TeamOutlined,
    BookOutlined,
    SettingOutlined,
    WarningOutlined,
    BarChartOutlined,
    CreditCardOutlined,
    FileDoneOutlined,
    AreaChartOutlined,
} from '@ant-design/icons';

import { BaseRouteModel } from '../../models/RouterModel';
import Dictionary from '../../pages/dictionary/Dictionary';
import RiskCriteria from '../../pages/riskCriteria/RiskCriteria';
import SuspicionIndicators from '../../pages/suspicionIndicators/SuspicionIndicators';
import Users from '../../pages/users/Users';
import Transactions from '../../pages/transactions/Transactions';
import RiskTransactions from '../../pages/riskTransactions/RiskTransactions';
import Reports from '../../pages/reports/Reports';
import Dashboard from '../../pages/dashboard/Dashboard';
import Alerts from "../../pages/alerts/Alerts";

export enum RouteCode {
    alerts = 'alerts',
    license = 'license',
    dashboard = 'dashboard',
    transactions = 'transactions',
    riscTransactions = 'transactions-risk',
    noRiskTransactions = 'transactions-no-risk',
    administration = 'administration',
    users = 'users',
    login = 'login',
    changePassword = 'change-password',
    dictionary = 'dictionary',
    riskCriteria = 'risk-criteria',
    suspicionIndicators = 'suspicion-indicators',
    reports = 'reports',
}

export const RouteList: readonly BaseRouteModel[] = [
    {
        path: `/${RouteCode.dashboard}`,
        code: RouteCode.dashboard,
        component: Dashboard,
        icon: AreaChartOutlined,
    },
    {
        path: `/${RouteCode.alerts}`,
        code: RouteCode.alerts,
        component: Alerts,
        icon: AreaChartOutlined,
    },
    {
        code: RouteCode.transactions,
        icon: CreditCardOutlined,
    },
    {
        path: `/${RouteCode.noRiskTransactions}`,
        code: RouteCode.noRiskTransactions,
        component: Transactions,
        icon: CreditCardOutlined,
    },
    {
        path: `/${RouteCode.riscTransactions}`,
        code: RouteCode.riscTransactions,
        component: RiskTransactions,
        icon: CreditCardOutlined,
    },
    {
        code: RouteCode.administration,
        icon: SettingOutlined,
    },
    {
        path: `/${RouteCode.users}`,
        code: RouteCode.users,
        component: Users,
        icon: TeamOutlined,
    },
    {
        path: `/${RouteCode.dictionary}`,
        code: RouteCode.dictionary,
        component: Dictionary,
        icon: BookOutlined,
    },
    {
        path: `/${RouteCode.riskCriteria}`,
        code: RouteCode.riskCriteria,
        component: RiskCriteria,
        icon: WarningOutlined,
    },
    {
        path: `/${RouteCode.suspicionIndicators}`,
        code: RouteCode.suspicionIndicators,
        component: SuspicionIndicators,
        icon: BarChartOutlined,
    },
    {
        path: `/${RouteCode.reports}`,
        code: RouteCode.reports,
        component: Reports,
        icon: FileDoneOutlined,
    },
];

import { BASE_NOTIFICATION_CONFIG } from '../constants';
import { notification } from 'antd';
import buildQuery, { QueryOptions } from 'odata-query';
import { Transaction } from '../models/Transaction';

const download = (data: Blob, fileName: string) => {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(data);
    a.download = fileName;
    a.click();
    a.remove();
};

const baseErrorMessage = 'Помилка завантаження звіту';

class FileService {
    static async load(url: string, fileName: string, onFinish?: () => void) {
        fetch(url)
            .then((res) => {
                if (res.ok) {
                    return res.blob();
                } else {
                    return Promise.reject(res);
                }
            })
            .then((data) => {
                download(data, fileName);
            })
            .catch(async (error: Response) => {
                let errMsg = '';
                try {
                    errMsg = await error.json().then((data) => data.message);
                } catch {}
                notification.error({
                    ...BASE_NOTIFICATION_CONFIG,
                    message: errMsg || baseErrorMessage,
                });
            })
            .finally(() => {
                if (onFinish) onFinish();
            });
    }
    static async downloadReport(type: string, name: string, date: string, onFinish: () => void) {
        this.load(
            `${process.env.REACT_APP_BASE_URL}/report/daily-report?type=${type}&date=${date}`,
            `${name}_${date}`,
            onFinish,
        );
    }

    static async downloadReportWithQuery(type: string, name: string, query: string, onFinish: () => void) {
        this.load(`${process.env.REACT_APP_BASE_URL}/report/report-with-param/${type}?${query}`, name, onFinish);
    }

    static async downloadTransactionReport(
        query: Partial<QueryOptions<Transaction>>,
        onFinish: () => void,
        fileName: string,
        isRisk: boolean,
        isMonthly: boolean,
    ) {
        const monthlyFilter = isMonthly ? '&monthly=1' : '&monthly=0';
        const riskFilter = isRisk ? '&risk=1' : '&risk=0';
        const queryString = buildQuery(query);
        this.load(
            `${process.env.REACT_APP_BASE_URL}/report/transactions-register${queryString}${monthlyFilter}${riskFilter}`,
            fileName,
            onFinish,
        );
    }

    static async downloadBlackList(name: string, activityType: string) {
        this.load(`${process.env.REACT_APP_BASE_URL}/report/${name}/${activityType}`, `${name}.xlsx`);
    }
}

export default FileService;
